import React from 'react';
import { navigate } from "gatsby";

const Home = () => {
	const isBrowser = typeof window !== 'undefined';
	if(isBrowser){
		navigate("/")

	}
    return (
		<>
		</>
    );
}

export default Home;
